import './bootstrap';

import Inputmask from "inputmask/lib/inputmask.js";

// MASCARAS DE INPUTS
document.addEventListener('DOMContentLoaded', function () {
    var cnpjMask = new Inputmask("99.999.999/9999-99");
    cnpjMask.mask($('#form-parceiro #cnpj'));

    var cpfMask = new Inputmask("999.999.999-99");
    cpfMask.mask($('#form-cliente #cpf, #register-user #cpf'));

    var cepMask = new Inputmask("99.999-999");
    cepMask.mask($('#form-cliente #cep, #form-parceiro #cep, #register-user #cep'));

    var telefoneMask = new Inputmask(["(99)9999-9999", "(99)99999-9999"]);
    telefoneMask.mask($('#form-cliente #telefone, #form-parceiro #telefone, #register-user #telefone'));

    var dataMask = new Inputmask("99/99/9999");
    dataMask.mask($('#register-user #data_nascimento'));

    // var moneyMask = new Inputmask( 'currency',{"autoUnmask": true,
    //     radixPoint:",",
    //     groupSeparator: ".",
    //     allowMinus: false,
    //     prefix: 'R$ ',
    //     digits: 2,
    //     digitsOptional: false,
    //     rightAlign: true,
    //     unmaskAsNumber: true
    // });
    // moneyMask.mask($('#form-cliente-contrato #valor, #form-parceiro-contrato #valor'));


    $('#form-cliente-contrato #valor, #form-parceiro-contrato #valor').maskMoney();


    //var emailMask = new Inputmask({ alias: "email"});
    // emailMask.mask($('#register-user #email'));
    // $("#register-user #email").inputmask({ alias: "email"});

    // var emailMask = new Inputmask({
    //     mask: "*{1,30}[.*{1,30}][.*{1,30}]@*{1,30}[.*{2,5}][.*{1,2}]",
    //     greedy: false,
    //     definitions: {
    //         '*': {
    //             validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
    //             casing: "lower"
    //         }
    //     }
    // });
    // emailMask.mask($('#register-user #email,#form-cliente #email'));

});

$(document).ready(function () {


    // TABS usados em user:cliente e user:parceiro
    $('#myTab a').each(function () {
        var fullHref = $(this).attr('href'); // Get the full href
        var url = new URL(fullHref); // Create a URL object
        var pathAndQuery = url.pathname + url.search; // Extract the path and query string
        if (pathAndQuery === window.location.pathname) {
            $(this).addClass('active');
        }
    });


    // CLIENTE

    // exclui arquivo de contrato de cliente via ajax
    $('#form-cliente-contrato #btn-excluir-arquivo').on('click', function () {
        var itemId = $(this).data('id');
        var csrfToken = $(this).data('token');
        var url = '/cliente_contrato/arquivo/' + itemId;

        if (confirm('Tem certeza que deseja apagar este arquivo?')) {
            $.ajax({
                url: url,
                //type: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/json'
                },
                success: function (response) {
                    //alert(response.success);
                    window.location.href = '/cliente_contratos/' + itemId + '/edit';
                },
                error: function (xhr) {
                    //alert('Error: ' + xhr.responseText);
                }
            });
        }
    });

    // foco no input de nome do cliente
    $('#form-cliente #name').focus();

    // PARCEIRO

    // exclui arquivo de contrato de parceiro via ajax
    $('#form-parceiro-contrato #btn-excluir-arquivo').on('click', function () {
        var itemId = $(this).data('id');
        var csrfToken = $(this).data('token');
        var url = '/parceiro_contrato/arquivo/' + itemId;

        if (confirm('Tem certeza que deseja apagar este arquivo?')) {
            $.ajax({
                url: url,
                //type: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/json'
                },
                success: function (response) {
                    //alert(response.success);
                    window.location.href = '/parceiro_contratos/' + itemId + '/edit';
                },
                error: function (xhr) {
                    //alert('Error: ' + xhr.responseText);
                }
            });
        }
    });

    // borda vermelha nos inputs de: parceiro-contrato, parceiro,  cliente-contrato e cliente
    $('#form-cliente-contrato input[required], #form-cliente-contrato select[required], ' +
        '#form-cliente input[required], #form-parceiro input[required], ' +
        '#form-parceiro-contrato input[required], #form-parceiro-contrato select[required]').each(function () {
        $(this).css('border-color', '#FAA0A0');
    });

    // FORM REGISTRO DE USER
    $('#register-user #name').focus();

    // exibe modal de contrato-cliente
    $('#openParceiroContratoModal').click(function () {
        // alert('aa');
        var itemId = $(this).data('id');
        var url = '/parceiro_contratos/' + itemId;
        $.ajax({
            url: url,
            method: 'GET',
            success: function (response) {
                $(".parceiro_id").attr('href', '/parceiros/' + response.parceiro.id + '/edit')
                $(".razao_social").html(response.parceiro.razao_social)
                $(".cnpj").html(response.parceiro.cnpj)
                $(".endereco").html(response.parceiro.endereco)
                $(".cidade").html(response.parceiro.cidade)
                $(".estado").html(response.parceiro.estado)
                $(".cep").html(response.parceiro.cep)
                $(".status").html(response.parceiro.status)
                $(".observacao").html(response.parceiro.observacao)

                $(".data_inicio").html(response.data_inicio)
                $(".data_fim").html(response.data_fim)
                $(".arquivo").html(response.arquivo)
                $(".obs").html(response.obs)
                $(".created_at").html(response.created_at)
                $(".updated_at").html(response.updated_at)

                $('#modalContratoParceiro').modal('show');
            },
            error: function () {
                $('#modalContent').html('<p>Error loading data.</p>');
                $('#modalContratoParceiro').modal('show');
            }
        });
    });

    // exibe modal de contrato-parceiro
    $('#openClienteContratoModal').click(function () {
        var itemId = $(this).data('id');
        var url = '/cliente_contratos/' + itemId;
        $.ajax({
            url: url,
            method: 'GET',
            success: function (response) {
                $(".cliente_id").attr('href', '/clientes/' + response.cliente.id + '/edit')
                $(".name").html(response.cliente.name)
                $(".cpf").html(response.cliente.cpf)
                $(".rg").html(response.cliente.rg)
                $(".endereco").html(response.cliente.endereco)
                $(".cidade").html(response.cliente.cidade)
                $(".estado").html(response.cliente.estado)
                $(".cep").html(response.cliente.cep)
                $(".status").html(response.cliente.status)
                $(".observacao").html(response.cliente.observacao)
                $(".filiacao_pai").html(response.cliente.filiacao_pai)
                $(".filiacao_mae").html(response.cliente.filiacao_mae)

                $(".data_inicio").html(response.data_inicio)
                $(".data_fim").html(response.data_fim)
                $(".arquivo").html(response.arquivo)
                $(".obs").html(response.obs)
                $(".created_at").html(response.created_at)
                $(".updated_at").html(response.updated_at)

                $('#modalContratoCliente').modal('show');
            },
            error: function () {
                $('#modalContent').html('<p>Error loading data.</p>');
                $('#modalContratoCliente').modal('show');
            }
        });
    });

    // function getUrlParameter(name) {
    //     const params = new URLSearchParams(window.location.search);
    //     return params.get(name);
    // }
    //
    // // Activate the corresponding tab based on the 'tab' parameter
    // document.addEventListener("DOMContentLoaded", function () {
    //     const tab = getUrlParameter('tab'); // Get 'tab' parameter from URL
    //     if (tab) {
    //         const targetTab = document.querySelector(`#${tab}`);
    //         const targetContent = document.querySelector(targetTab.getAttribute('href'));
    //
    //         if (targetTab && targetContent) {
    //             // Remove 'active' class from current active tabs and contents
    //             document.querySelector('.nav-link.active').classList.remove('active');
    //             document.querySelector('.tab-pane.active').classList.remove('show', 'active');
    //
    //             // Add 'active' class to the target tab and content
    //             targetTab.classList.add('active');
    //             targetContent.classList.add('show', 'active');
    //         }
    //     }
    // });





});

// function show_hide_required_servico() {
//     if ($('#form-parceiro-contrato #servicos').val()=='') {
//         alert('0');
//         $('#form-parceiro-contrato #servico').attr('required', true).css('border-color', 'rgb(250, 160, 160)');
//         $('#form-parceiro-contrato #desconto').attr('required', true).css('border-color', 'rgb(250, 160, 160)');
//     } else {
//         alert('1');
//         $('#form-parceiro-contrato #servico').attr('required', false).css('border-color', 'dee2e6');
//         $('#form-parceiro-contrato #desconto').attr('required', false).css('border-color', 'dee2e6');
//     }
// }